import normalizeUrl from './normalizeUrl';

export const pathHierarchy = {
  EXACT: 'exact',
  OUTSIDE: 'outside',
  WITHIN: 'within',
};

export default function comparePathToUrl(path, url) {
  const normalizedUrl = normalizeUrl(url);
  if (path === normalizedUrl) {
    return pathHierarchy.EXACT;
  }
  if (path.startsWith(normalizedUrl)) {
    return pathHierarchy.WITHIN;
  }
  return pathHierarchy.OUTSIDE;
}
