import { useRouter } from 'next/router';

import truthyQueryParameter from './truthyQueryParameter';

export default function useBodyOnly() {
  const { query } = useRouter();

  // allowing for multiple case options for this bodyOnly parameter
  return (
    truthyQueryParameter(query?.bodyonly) ||
    truthyQueryParameter(query?.bodyOnly) ||
    truthyQueryParameter(query?.BODYONLY)
  );
}
