import React from 'react';

import PropTypes from 'prop-types';
import styled from 'styled-components';

import Link from '~/shared/components/Link';
import useSecondarySite from '~/shared/utils/useSecondarySite';
import { Logo } from '~/techstyle-shared/react-builder-models';
import { FableticsOutletLogo } from '~/techstyle-shared/react-components';

import AssetContainerLogo from './AssetContainerLogo';
import PreloadedLogo from './PreloadedLogo';

const StyledLink = styled(Link)`
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  text-decoration: none;
`;

function BuilderLogo({ type, assetContainer }) {
  const secondarySite = useSecondarySite();
  const isAssetContainer = type === Logo.TYPES.ASSET_CONTAINER;
  const logo = secondarySite ? (
    <FableticsOutletLogo width={200} />
  ) : (
    <PreloadedLogo type={type} />
  );
  const href = secondarySite ? `/${secondarySite}` : '/';

  return (
    <StyledLink href={href} data-builder-component-type="logo" passHref>
      {isAssetContainer ? <AssetContainerLogo name={assetContainer} /> : logo}
    </StyledLink>
  );
}

BuilderLogo.propTypes = {
  assetContainer: PropTypes.string,
  type: PropTypes.string,
};

export default BuilderLogo;
