import React from 'react';

import useGenesysWebChatAction from '~/shared/utils/useGenesysWebChatAction';
import { GenesysWebChatSnippet } from '~/techstyle-shared/react-marketing';

export default function GenesysWebChatScript() {
  const { deploymentId } = useGenesysWebChatAction();

  return (
    <>
      <GenesysWebChatSnippet deploymentId={deploymentId} />
    </>
  );
}
