import React, { createContext, useContext } from 'react';

import PropTypes from 'prop-types';

import { useSearchHistory } from '~/techstyle-shared/react-search';

import {
  HISTORY_STORAGE_KEY,
  MAX_HISTORY_LENGTH,
} from '../constants/cioConstants';

const CioSearchHistoryContext = createContext();

export const useCioSearchHistory = () => useContext(CioSearchHistoryContext);

export const CioSearchHistoryProvider = ({ children }) => {
  /* 
  We have to hoist the useSearchHistory hook state to a context,
  because sibling components are not aware of the common state change when using 
  useSearchHistory directly, resulting in bugs where entering new search terms keeps adding 
  stale history back in even after the clear call button was clicked.
  */
  const {
    history,
    add: addHistory,
    clear: clearHistory,
  } = useSearchHistory({
    maxLength: MAX_HISTORY_LENGTH,
    storageKey: HISTORY_STORAGE_KEY,
  });

  return (
    <CioSearchHistoryContext.Provider
      value={{ history, addHistory, clearHistory }}
    >
      {children}
    </CioSearchHistoryContext.Provider>
  );
};

CioSearchHistoryProvider.propTypes = {
  children: PropTypes.node.isRequired,
};
