import React, { useRef } from 'react';

import PropTypes from 'prop-types';
import styled from 'styled-components';

import NavLink from '~/shared/components/NavLink';
import { mobile, desktop } from '~/techstyle-shared/react-components';

const SectionMenuLink = styled(NavLink)`
  color: inherit;
  display: inline-block;
  cursor: pointer;
  ${({ theme }) => theme.footerStyles.sectionMenuLinkStyle}
  ${mobile`
    :link {
      display: inline-block;
    }
  `}
  ${desktop`
    &:link:hover [data-nav-item-label] {
      text-decoration: underline;
    }
  `}
`;

const OneTrustButton = styled.div`
  display: none;
`;

export default function OneTrustLink({ navItem, autotag }) {
  const cookieSetting = useRef(null);

  const settingClick = () => {
    cookieSetting.current.click();
  };
  return (
    <>
      <SectionMenuLink
        {...navItem}
        data-autotag={autotag}
        onClick={settingClick}
      />
      <OneTrustButton>
        <button
          type="button"
          ref={cookieSetting}
          id="ot-sdk-btn"
          className="ot-sdk-show-settings"
        />
      </OneTrustButton>
    </>
  );
}

OneTrustLink.propTypes = {
  autotag: PropTypes.string,
  navItem: PropTypes.object,
};
