import { useEffect } from 'react';

import useGshop from '~/shared/utils/useGshop';

export default function Gshop() {
  const { activateGshop } = useGshop();

  useEffect(() => {
    activateGshop();
  }, [activateGshop]);

  return null;
}

Gshop.propTypes = {};
