import React, { useCallback } from 'react';

import PropTypes from 'prop-types';
import styled from 'styled-components';

import CloseButton from '~/shared/components/CloseButton';
import {
  FlagIcon,
  useBreakpoint,
  mobile,
  desktop,
} from '~/techstyle-shared/react-components';
import { useSuggestedDomain } from '~/techstyle-shared/react-intl';
import { useDomain, useCookies } from '~/techstyle-shared/redux-core';

const BannerWrapper = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;
  z-index: 2;
  width: 100%;

  ${desktop`
    max-width: 1012px;
    min-height: 40px;
    margin: 0 auto;
  `}

  ${mobile`
    position: fixed;
    bottom: 0;
    padding: 10px 0 15px;
    background: white;
    box-shadow: 0 -2px 3px 0 rgba(0, 0, 0, 0.2);
  `}
`;

const MessageWrapperLink = styled.a`
  display: flex;
  justify-content: space-between;
  align-items: center;
  text-align: center;
  width: 100%;
  text-decoration: none;

  ${desktop`
    width: 90%;
    padding: 5px 20px;
  `}
`;

const DismissButton = styled(CloseButton)`
  padding: 2px 10px 0;
  cursor: pointer;

  ${mobile`
    width: 44px;
    height: 44px;
  `}
`;

const StyledFlagIcon = styled(FlagIcon)`
  flex: 0 0 auto;
  width: 21px;
  margin: 0 15px 0;

  ${mobile`width: 34px;`}
`;

/**
 * Generic banner message that accepts and renders message copy from the `children`
 * if the user has not dismissed the banner
 * @param {*} children The message you would like to display in the banner
 * @param {string} cookieName Cookie key to store if user has dismissed the banner
 * @returns {component|null}
 */
export default function BannerMessage({ overrideLink, children, cookieName }) {
  const { suggestedTld, geoCountry } = useSuggestedDomain();
  const { getDomainRedirect } = useDomain();
  const { desktop } = useBreakpoint();
  const link = overrideLink || getDomainRedirect({ tld: suggestedTld });

  // user dismissed banners set corresponding cookie value to '1'
  const [cookies, setCookie] = useCookies([cookieName]);
  const bannerIsClosed = cookies[cookieName] === '1';

  const dismissAlert = useCallback(() => {
    setCookie(cookieName, '1');
  }, [cookieName, setCookie]);

  if (!children) {
    return null;
  }

  return !bannerIsClosed ? (
    <BannerWrapper>
      <MessageWrapperLink href={link}>
        <StyledFlagIcon country={geoCountry} />
        <div>{children}</div>
        {desktop ? <StyledFlagIcon country={geoCountry} /> : null}
      </MessageWrapperLink>
      <DismissButton onClick={dismissAlert} />
    </BannerWrapper>
  ) : null;
}

BannerMessage.propTypes = {
  children: PropTypes.node.isRequired,
  cookieName: PropTypes.string.isRequired,
  overrideLink: PropTypes.string,
};
