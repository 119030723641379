import React from 'react';

import config from 'config';
import styled from 'styled-components';

import SocialMediaIcons from '~/icons/components/SocialMediaIcons';
import featureNames from '~/shared/constants/featureFlags';
import useGenesysWebChatAction from '~/shared/utils/useGenesysWebChatAction';
import useHasFeature from '~/shared/utils/useHasFeature';
import { desktop, mobile } from '~/techstyle-shared/react-components';

import CognigyWebChatScript from '../CognigyWebChatScript';
import CompanyInfo from '../CompanyInfo';
import Copyright from '../Copyright';
import FooterFreeShippingDetails from '../FooterFreeShippingDetails';
import FooterNav from '../FooterNav';
import GenesysWebChatScript from '../GenesysWebChatScript';

const Wrapper = styled.footer`
  position: relative;
  color: ${({ theme }) => theme.colors.linkTextDeemphasizedAlternate};
  background: ${({ theme }) => theme.colors.backgroundDefault};
  text-align: center;
  -webkit-font-smoothing: antialiased;
  display: flex;
  flex-direction: column;
  z-index: 0;

  ${desktop`
    a:hover {
      text-decoration: underline;
    }
  `}

  ${({ theme }) => theme.footerStyles.wrapperStyle}
`;

const ContentSection = styled.div`
  padding-top: 32px;

  ${mobile`
    font-size: 12px;
    padding: 0 ${({ theme }) => theme.space('xl')};
  `}
`;

const Divider = styled.hr`
  ${({ theme }) => theme.footerStyles.dividerStyle};
`;

const FooterCopyrightLink = styled(Copyright)`
  display: inline-block;
`;

const CompanyInfoWrapper = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  font-size: 12px;

  ${desktop`
    flex-direction: row;
  `}
`;

const Contact = styled.div`
  display: flex;
  align-items: center;
  padding: 2em;
  justify-content: center;
  background: ${({ theme }) => theme.colors.backgroundDarkAlternate};

  ${desktop`
    justify-content: space-between;
    padding: ${({ theme }) => theme.spacing.sizes.pixels.m}px 10%;
  `}
`;

const Separator = styled.span`
  ${mobile`
    display: none;
  `}
`;

const SocialWrapper = styled.div`
  ${({ theme }) => theme.footerStyles.socialIconWrapperStyle}
`;

const MobileOnly = styled.span`
  ${desktop`
    display: none;
  `}
  ${mobile`
    display: block;
  `}
`;

const DesktopOnly = styled.span`
  ${desktop`
    display: block;
  `}
  ${mobile`
    display: none;
  `}
`;

const showFreeShippingOffer = config.get(
  'public.brand.showFooterFreeShippingOffer'
);

export default function Footer() {
  const { genensysFeatureEnabler } = useGenesysWebChatAction();
  const cognigyFeatureEnabler = useHasFeature(featureNames.COGNIGY_WEB_CHAT);

  return (
    <Wrapper>
      <MobileOnly>
        <SocialWrapper>
          <SocialMediaIcons hideLabel />
        </SocialWrapper>
      </MobileOnly>

      <DesktopOnly>
        {showFreeShippingOffer && <FooterFreeShippingDetails />}
      </DesktopOnly>

      <ContentSection>
        <FooterNav />
      </ContentSection>

      <MobileOnly>
        {showFreeShippingOffer && <FooterFreeShippingDetails />}
      </MobileOnly>

      <Divider />

      <Contact>
        <CompanyInfoWrapper>
          <CompanyInfo />
          <Separator>&#8212;</Separator>
          <FooterCopyrightLink data-autotag="footer_copyright" />
        </CompanyInfoWrapper>

        <DesktopOnly>
          <SocialMediaIcons hideLabel />
        </DesktopOnly>
      </Contact>

      {genensysFeatureEnabler && <GenesysWebChatScript />}
      {cognigyFeatureEnabler && <CognigyWebChatScript />}
    </Wrapper>
  );
}
