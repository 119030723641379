import React from 'react';

import PropTypes from 'prop-types';
import styled from 'styled-components';

import { Link } from '~/techstyle-shared/next-routes';
import { FormattedMessage, useIntl } from '~/techstyle-shared/react-intl';
import { useReferringPage } from '~/techstyle-shared/react-marketing';

import normalizeUrl from '../../utils/normalizeUrl';
import useLocalizedPath from '../../utils/useLocalizedPath';
import usePathHierarchy from '../../utils/usePathHierarchy';

const NavItemLabel = styled.span`
  display: inline-block;
`;
const DynamicFormattedMessage = FormattedMessage;

const SPACE_REGEX = /\s+/g;
const createDataAutoTagProp = (navLinkParent, navLinkLabel) => {
  const ret = {};

  if (navLinkParent) {
    ret['data-autotag'] = `${navLinkParent}_${
      navLinkLabel ? navLinkLabel.replace(SPACE_REGEX, '_') : navLinkLabel
    }`;
  }

  return ret;
};

const DisplayLabel = ({ isResourceBundle, label }) => {
  const { formatMessage } = useIntl();

  if (isResourceBundle) {
    return (
      <DynamicFormattedMessage
        id={`site_navigation.${label}`}
        defaultMessage={label}
      />
    );
  }
  if (typeof label === 'object' && label.id) {
    return formatMessage(label);
  }
  return label;
};

DisplayLabel.propTypes = {
  isResourceBundle: PropTypes.bool,
  label: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
};

export default function NavLink(props) {
  const {
    children,
    appendChildren,
    className,
    'data-autotag': autoTag,
    featureFlag1: isNew,
    featureFlag2: isSale,
    featureFlag3: isLimitedTime,
    gaLabel,
    isResourceBundle,
    itemKey,
    label,
    parentElement,
    onClick,
    showFlags,
    tagName = props.url ? 'a' : 'span',
    url,
    ...rest
  } = props;
  let prependChildren = true;
  if (appendChildren) {
    prependChildren = false;
  }
  const localizedUrl = useLocalizedPath(url || '');
  const normalizedUrl = localizedUrl ? normalizeUrl(localizedUrl) : undefined;

  const currentPathHierarchy = usePathHierarchy(url);

  const gaTags = Object.keys(rest).reduce((obj, key) => {
    if (key.startsWith('data-ga-')) {
      obj[key] = props[key];
    }
    return obj;
  }, {});

  const Tag = tagName;
  const { setReferringPage } = useReferringPage();

  const handleClick = () => {
    setReferringPage(gaLabel);
  };

  const renderTag = () => {
    return (
      <Tag
        className={className}
        data-autotag={autoTag}
        onClick={onClick || handleClick}
        data-path-hierarchy={currentPathHierarchy}
        {...gaTags}
      >
        {prependChildren && children}
        {label ? (
          <NavItemLabel
            isSale={isSale}
            data-nav-item-label=""
            {...createDataAutoTagProp(parentElement, label)}
          >
            <DisplayLabel
              isResourceBundle={isResourceBundle}
              label={label}
              {...rest}
            />
          </NavItemLabel>
        ) : null}
        {appendChildren && children}
      </Tag>
    );
  };

  return url ? (
    <Link href={normalizedUrl} passHref>
      {renderTag()}
    </Link>
  ) : (
    renderTag()
  );
}
NavLink.NavItemLabel = NavItemLabel;
NavLink.propTypes = {
  appendChildren: PropTypes.bool,
  children: PropTypes.node,
  className: PropTypes.string,
  'data-autotag': PropTypes.string,
  featureFlag1: PropTypes.bool,
  featureFlag2: PropTypes.bool,
  featureFlag3: PropTypes.bool,
  gaLabel: PropTypes.string,
  isMens: PropTypes.bool,
  isResourceBundle: PropTypes.bool,
  itemKey: PropTypes.string,
  label: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.element,
    PropTypes.func,
  ]),
  onClick: PropTypes.func,
  parentElement: PropTypes.string,
  showFlags: PropTypes.bool,
  tagName: PropTypes.string,
  url: PropTypes.string,
  whiteSpace: PropTypes.string,
};
