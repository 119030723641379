import React, { useState } from 'react';

import PropTypes from 'prop-types';
import { FaChevronDown } from 'react-icons/fa';
import styled from 'styled-components';

import CertifiedNeutralIcon from '~/icons/components/CertifiedNeutral';
import CircularEconomy from '~/icons/components/CircularEconomy';
import SewNeedle from '~/icons/components/SewNeedle';
import { Link } from '~/techstyle-shared/next-routes';
import { FormattedMessage } from '~/techstyle-shared/react-intl';

const DynamicFormattedMessage = FormattedMessage;

const Wrapper = styled.div`
  background: ${({ theme }) => theme.colors.backgroundAccounts};
  text-align: center;
  padding: 37px 20px;

  @media (min-width: ${({ theme }) => theme.breakpoints.tablet.maxWidth}px) {
    padding: 61px 0;
    margin: 0 auto;
  }
`;

const Title = styled.p`
  font-weight: 700;
  font-size: 24px;
  line-height: 28px;

  @media (min-width: ${({ theme }) => theme.breakpoints.tablet.maxWidth}px) {
    font-weight: 700;
    font-size: 36px;
    line-height: 47px;
  }
`;

const Subtitle = styled.p`
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  padding-bottom: 34px;

  @media (min-width: ${({ theme }) => theme.breakpoints.tablet.maxWidth}px) {
    font-weight: 400;
    font-size: 16px;
    padding-bottom: 24px;
  }
`;

const CardsWrapper = styled.div`
  justify-content: center;
  max-width: 90vw;
  margin: 0 auto;

  @media (min-width: ${({ theme }) => theme.breakpoints.tablet.maxWidth}px) {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 10px;
  }
`;

const CardWrapper = styled.div`
  display: grid;
  text-align: left;
  padding: 22px 0px;
  margin-bottom: 0;
  &:not(:last-child) {
    @media (max-width: ${({ theme }) => theme.breakpoints.tablet.maxWidth}px) {
      border-bottom: 1px solid
        ${({ theme }) => theme.colors.sustainabilityCardWrapperBorder};
    }
  }

  @media (min-width: ${({ theme }) => theme.breakpoints.tablet.maxWidth}px) {
    text-align: center;
    background: ${({ theme }) => theme.colors.white};
    border-radius: 15px;
    padding: 48px 24px;
    display: grid;
    grid-template-rows: auto 1fr;
  }
`;

const CardTitleWrapper = styled.div`
  display: flex;
  align-items: center;

  @media (min-width: ${({ theme }) => theme.breakpoints.tablet.maxWidth}px) {
    justify-content: center;
    padding-bottom: 11px;
    align-self: start;
  }
`;

const CardTitle = styled.div`
  font-weight: 700;
  font-size: 24px;
  padding-left: 8px;
  flex-grow: 1;

  @media (min-width: ${({ theme }) => theme.breakpoints.tablet.maxWidth}px) {
    flex-grow: 0;
    font-weight: 700;
    font-size: 24px;
    padding-left: 5px;
  }
`;

const CardBodyWrapper = styled.div`
  padding-left: 36px;
  padding-top: 9px;
  display: ${({ $isClosed }) => ($isClosed ? 'none' : 'block')};

  @media (min-width: ${({ theme }) => theme.breakpoints.tablet.maxWidth}px) {
    padding-left: 0;
    display: grid;
  }
`;

const CardBodyText = styled.div`
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  padding-bottom: 7px;
  font-size: 14px;

  @media (min-width: ${({ theme }) => theme.breakpoints.tablet.maxWidth}px) {
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    padding-bottom: 16px;
  }
`;

const LearnMoreLink = styled.a`
  align-self: end;
  font-weight: 700;
  font-size: 16px;
  text-decoration: underline;
  text-transform: uppercase;

  @media (min-width: ${({ theme }) => theme.breakpoints.tablet.maxWidth}px) {
    align-self: end;
    font-weight: 700;
    font-size: 16px;
    text-decoration: underline;
    text-transform: uppercase;
  }
`;

const ChevronIcon = styled(FaChevronDown)`
  transform: ${({ $isClosed }) => ($isClosed ? 'none' : 'rotate(180deg)')};

  @media (min-width: ${({ theme }) => theme.breakpoints.tablet.maxWidth}px) {
    display: none;
  }
`;

const IconWrapper = styled.div`
  width: 28px;
  height: 28px;
  display: flex;
  justify-content: center;
  align-items: center;

  @media (min-width: ${({ theme }) => theme.breakpoints.tablet.maxWidth}px) {
    width: auto;
  }
`;

const Card = ({ icon, title, body, titleRBid, bodyRBid }) => {
  const [isClosed, setIsClosed] = useState(true);

  const toggle = () => setIsClosed(!isClosed);

  return (
    <CardWrapper>
      <CardTitleWrapper onClick={toggle}>
        <IconWrapper>{icon}</IconWrapper>
        <CardTitle>
          <DynamicFormattedMessage defaultMessage={title} id={titleRBid} />
        </CardTitle>
        <ChevronIcon $isClosed={isClosed} />
      </CardTitleWrapper>
      <CardBodyWrapper $isClosed={isClosed}>
        <CardBodyText>
          <DynamicFormattedMessage defaultMessage={body} id={bodyRBid} />
        </CardBodyText>
        <Link href="/sustainability">
          <LearnMoreLink>
            <FormattedMessage
              defaultMessage="Learn More"
              id="fl_sustainability_banner.learn_more_text"
            />
          </LearnMoreLink>
        </Link>
      </CardBodyWrapper>
    </CardWrapper>
  );
};

Card.propTypes = {
  body: PropTypes.string,
  bodyRBid: PropTypes.string,
  icon: PropTypes.node,
  title: PropTypes.string,
  titleRBid: PropTypes.string,
};

const SustainabilityBanner = () => {
  const cards = [
    {
      icon: <SewNeedle />,
      title: '100% Sweatshop Free',
      body: 'We invest in independent audits to ensure that every Fabletics factory follows the heighest standards of care.',
      titleRBid: 'fl_sustainability_banner.sweatshop_free_title',
      bodyRBid: 'fl_sustainability_banner.sweatshop_free_body',
    },
    {
      icon: <CertifiedNeutralIcon />,
      title: '100% Carbon Neutral',
      body: 'Since 2021, we have been a certified CarbonNeutral® company in accordance with The CarbonNeutral® Protocol.',
      titleRBid: 'fl_sustainability_banner.carbon_neutral_title',
      bodyRBid: 'fl_sustainability_banner.carbon_neutral_body',
    },
    {
      icon: <CircularEconomy />,
      title: 'Recycled Materials',
      body: 'All of our polybags, mailers, cartons and tissue packaging are made from recycled materials.',
      titleRBid: 'fl_sustainability_banner.recycled_materials_title',
      bodyRBid: 'fl_sustainability_banner.recycled_materials_body',
    },
  ];

  return (
    <Wrapper>
      <Title>
        <FormattedMessage
          defaultMessage="Designed With An Eco-Conscious Mindset"
          id="fl_sustainability_banner.title"
        />
      </Title>
      <Subtitle>
        <FormattedMessage
          defaultMessage="At Fabletics, we continuously seek new ways to increase our environment-first approach."
          id="fl_sustainability_banner.subtitle"
        />
      </Subtitle>
      <CardsWrapper>
        {cards.map((card, i) => {
          const { icon, title, body, titleRBid, bodyRBid } = card;
          return (
            <Card
              key={i}
              icon={icon}
              title={title}
              body={body}
              titleRBid={titleRBid}
              bodyRBid={bodyRBid}
            />
          );
        })}
      </CardsWrapper>
    </Wrapper>
  );
};

export default SustainabilityBanner;
