import { useCallback, useEffect } from 'react';

import {
  COGNIGY_COOKIE_NAME,
  ENABLE_WIDGET_TOGGLE,
  WEBCHAT_COOKIE_EXPIRATION,
} from '~/shared/constants/cognigy';
import { useCookies } from '~/techstyle-shared/redux-core';

const useCognigyWebchatAction = () => {
  const [cookies, setCookie] = useCookies([COGNIGY_COOKIE_NAME]);

  useEffect(() => {
    if (!window?.webchat) {
      return;
    }

    const webchat = window.webchat;
    const url = new URL(window.location);
    const isProfilePage = url.pathname.includes('/account/profile');
    const showChatForAppUser =
      url.searchParams.get('fromMobileApp') && isProfilePage;
    const hasCognigyChatStarted = cookies[COGNIGY_COOKIE_NAME];

    // Chat should pop up when the user is coming from the mobile app.
    // https://techstyle-prod.atlassian.net/browse/FLR-631
    if (!hasCognigyChatStarted && showChatForAppUser) {
      const {
        config: { settings },
      } = webchat.store.getState();

      webchat.updateSettings({
        ...settings,
        ...ENABLE_WIDGET_TOGGLE,
      });

      webchat.open();

      const expireCognigyCookie = new Date(
        new Date().getTime() + WEBCHAT_COOKIE_EXPIRATION
      );
      setCookie([COGNIGY_COOKIE_NAME], true, {
        expires: expireCognigyCookie,
      });
    }
  }, [cookies, setCookie]);

  const cognigyHandler = useCallback(() => {
    if (!window?.webchat) {
      return;
    }

    const webchat = window.webchat;
    const hasCognigyChatStarted = cookies[COGNIGY_COOKIE_NAME];
    const {
      config: { settings },
    } = webchat.store.getState();

    webchat.updateSettings({
      ...settings,
      ...ENABLE_WIDGET_TOGGLE,
    });

    webchat.open();

    if (!hasCognigyChatStarted) {
      const expireCognigyCookie = new Date(
        new Date().getTime() + WEBCHAT_COOKIE_EXPIRATION
      );
      setCookie([COGNIGY_COOKIE_NAME], true, {
        expires: expireCognigyCookie,
      });
    }
  }, [cookies, setCookie]);

  return {
    cognigyHandler,
  };
};

export default useCognigyWebchatAction;
