import React, { useState, useCallback } from 'react';

import 'intersection-observer';
import Observer from '@researchgate/react-intersection-observer';
import config from 'config';
import { FiArrowUp } from 'react-icons/fi';
import styled from 'styled-components';

import { COGNIGY_COOKIE_NAME } from '~/shared/constants/cognigy';
import {
  ScrollToTop,
  desktop,
  mobile,
} from '~/techstyle-shared/react-components';
import { FormattedMessage } from '~/techstyle-shared/react-intl';
import { useCookies } from '~/techstyle-shared/redux-core';

import useGenesysWebChatAction from '../../utils/useGenesysWebChatAction';
import VisuallyHidden from '../VisuallyHidden';

const BackToTopButton = styled(ScrollToTop)`
  ${desktop`
    right: 6vw;
    bottom: 25px;
    height: 44px;
    width: 44px;
  `};

  ${mobile`
    right: 6vw;
    bottom: ${({ chatIconVisible }) => (chatIconVisible ? '90px' : '21px')};
    height: 58px;
    width: 58px;
  `};

  cursor: pointer;
  position: fixed;
  visibility: ${({ isVisible }) => (isVisible ? 'visible' : 'hidden')};
  opacity: ${({ isVisible }) => (isVisible ? '1' : '0')};
  transition: all 0.3s ease;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  box-shadow: 0px 3px 4px rgba(0, 0, 0, 0.15);
  border-radius: 50%;
  font-size: 10px;
  font-weight: bold;
  letter-spacing: 0.025em;
  line-height: 1.33;
  text-align: center;
  text-transform: uppercase;
  ${({ theme }) => theme.backToTopButton}
`;

const IntersectionContainer = styled.div`
  position: absolute;
  top: -1px;
  left: 0;
  right: 0;
  height: 1px;
`;

const showBackToTopText = config.get('public.brand.showBackToTopText');

const MaybeVisuallyHidden = showBackToTopText
  ? ({ children }) => children
  : VisuallyHidden;

export default function BackToTop() {
  const [isVisible, setVisible] = useState(false);
  const { showIconOnMobileApp, showMsgAlways } = useGenesysWebChatAction();
  const [cookies] = useCookies([COGNIGY_COOKIE_NAME]);
  const hasCognigyChatStarted = cookies[COGNIGY_COOKIE_NAME];
  const handleIntersection = useCallback(({ isIntersecting }) => {
    setVisible(!isIntersecting);
  }, []);

  return (
    <>
      <Observer onChange={handleIntersection} rootMargin="45% 0px 0px 0px">
        <IntersectionContainer />
      </Observer>
      <BackToTopButton
        isVisible={isVisible}
        data-autotag="back-to-top"
        chatIconVisible={
          showMsgAlways || showIconOnMobileApp || hasCognigyChatStarted
        }
      >
        <FiArrowUp size={24} />
        <MaybeVisuallyHidden>
          <FormattedMessage id="global_cta.back_to_top" defaultMessage="Top" />
        </MaybeVisuallyHidden>
      </BackToTopButton>
    </>
  );
}
