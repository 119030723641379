import React from 'react';

import { BuilderComponent } from '@builder.io/react';
import { useRouter } from 'next/router';
import { useDispatch } from 'react-redux';

import { trackBuilderTest } from '~/builder/utils/builderTesting';
import { isBuilderPreview } from '~/builder/utils/isBuilderPreview';
import { useBuilderContent } from '~/builder/utils/useBuilderContent';
import useBuilderJson from '~/builder/utils/useBuilderJson';
import featureNames from '~/shared/constants/featureFlags';
import { isSavage } from '~/shared/utils/brandNameHelper';
import useGenesysWebChatAction from '~/shared/utils/useGenesysWebChatAction';
import useHasFeature from '~/shared/utils/useHasFeature';
import useIsServerSafeMobile from '~/shared/utils/useIsServerSafeMobile';
import useSecondarySite, {
  getSecondarySiteBuilderModelQuery,
} from '~/shared/utils/useSecondarySite';
import { useBreakpoint } from '~/techstyle-shared/react-components';

import CognigyWebChatScript from '../CognigyWebChatScript';
import Footer from '../Footer';
import GenesysWebChatScript from '../GenesysWebChatScript';
import SustainabilityBanner from '../SustainabilityBanner';

export default function BuilderFooterRenderer() {
  const { matches: isTablet } = useBreakpoint('tablet');
  const { query } = useRouter();
  const isMobileOrTablet = useIsServerSafeMobile() || isTablet;
  const secondarySite = useSecondarySite();
  const { genensysFeatureEnabler } = useGenesysWebChatAction();
  const cognigyFeatureEnabler = useHasFeature(featureNames.COGNIGY_WEB_CHAT);

  const footerSectionModelProperties = {
    modelName: 'footer',
    query: {
      data: {
        variant: isMobileOrTablet ? 'mobile' : 'desktop',
        secondarySite: getSecondarySiteBuilderModelQuery(secondarySite),
      },
    },
    builderQueries: {
      isPreview: isBuilderPreview({ query }),
    },
  };

  const dispatch = useDispatch();
  const { builderJson, isLoading } = useBuilderJson(
    footerSectionModelProperties
  );
  const { content, locale } = useBuilderContent(builderJson);

  if (isLoading || !builderJson) {
    return (
      <>
        {!isSavage() && (
          <>
            <SustainabilityBanner />
            <Footer />
          </>
        )}
      </>
    );
  }

  return (
    <footer>
      <BuilderComponent
        model="footer"
        content={content}
        locale={locale}
        contentLoaded={(data, content) => {
          trackBuilderTest(dispatch, content);
        }}
      />
      {genensysFeatureEnabler && <GenesysWebChatScript />}
      {cognigyFeatureEnabler && <CognigyWebChatScript />}
    </footer>
  );
}
