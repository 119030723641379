import React, { useCallback, useState } from 'react';

import Observer from '@researchgate/react-intersection-observer';
import { rgba } from 'polished';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import {
  RadialProgressBar,
  useCountdown,
  useTheme,
} from '~/techstyle-shared/react-components';
import { FormattedMessage } from '~/techstyle-shared/react-intl';

import usePostRegCountdownEndTime from '../../utils/usePostRegCountdownEndTime';
import usePostRegTimer from '../../utils/usePostRegTimer';

const CountdownTimerWrapper = styled.div`
  transition: all 0.3s ease;
  display: flex;
  align-items: center;
  padding: 10px;
  visibility: ${({ isVisible }) => (isVisible ? 'visible' : 'hidden')};
  opacity: ${({ isVisible }) => (isVisible ? '1' : '0')};

  ${({ $variantStyle, theme }) =>
    theme.tabsNode.postRegTabs[$variantStyle]?.countdown
      ?.countdownTimerWrapper};
`;

const TimerHeader = styled.div`
  display: flex;
  font-weight: 700;
  color: ${({ theme }) => theme.colors.palette.black};
  font-size: 24px;
  margin-right: 10px;

  ${({ $variantStyle, theme }) =>
    theme.tabsNode.postRegTabs[$variantStyle]?.countdown?.timerHeader};
`;

const TimerWrapper = styled.div`
  display: flex;
  align-items: center;
  font-size: 10px;
  padding: 5px;
  color: ${({ theme }) => theme.colors.textDark};
`;

const TimerLabel = styled.div`
  display: flex;
  text-align: center;
  font-size: 7px;
  font-weight: 700;
  color: ${({ theme }) => theme.colors.palette.white};

  ${({ $variantStyle, theme }) =>
    theme.tabsNode.postRegTabs[$variantStyle]?.countdown?.timerLabel};
`;

const CountdownWrapper = styled.div`
  position: relative;
  display: flex;
  width: 30px;
  height: 30px;
  flex-direction: row;

  ${({ $variantStyle, $isDisabled }) => `
    opacity: ${$variantStyle === 'floating' && $isDisabled ? '0.6' : 'unset'};
  `}

  ${({ $variantStyle, theme }) =>
    theme.tabsNode.postRegTabs[$variantStyle]?.countdown?.countdownWrapper};
`;

const TimeWrapper = styled.div`
  position: absolute;
  top: 1px;
  left: 1px;
  display: flex;
  align-items: center;
  height: 28px;
  width: 28px;
  justify-content: center;
  border-radius: 100%;
  border: 1px solid ${({ theme }) => theme.colors.postRegTimeWrapperBorder};
`;

const TimerSeparator = styled.div`
  font-size: 1.35em;
  line-height: 2;
  padding: 3px;
  color: ${({ theme }) => theme.colors.palette.black};

  ${({ $variantStyle, theme }) =>
    theme.tabsNode.postRegTabs[$variantStyle]?.countdown?.timerWrapper};
`;

const Time = styled.div`
  font-size: 12px;
  font-weight: 700;
  color: ${({ theme }) => theme.colors.palette.black};

  ${({ $variantStyle, theme }) =>
    theme.tabsNode.postRegTabs[$variantStyle]?.countdown?.time};
`;

const StyleRadialProgressBar = styled(RadialProgressBar)`
  position: relative;
  z-index: 1;
`;

const StyledTime = styled.span`
  color: ${({ theme }) => theme.colors.postRegTime};
`;

const IntersectionContainer = styled.div`
  position: absolute;
  top: -1px;
  left: 0;
  right: 0;
  height: 1px;
`;

export default function MobilePostRegCountdown({ $variantStyle }) {
  const isPostRegTimerVisible = usePostRegTimer();
  const { countDownEndTime } = usePostRegCountdownEndTime();
  const { hours, isTimerActive, minutes, padNumber, seconds } =
    useCountdown(countDownEndTime);
  const theme = useTheme();

  const [isVisible, setVisible] = useState(false);

  const handleIntersection = useCallback(({ isIntersecting }) => {
    setVisible(!isIntersecting);
  }, []);

  const radialProgressBarProps = {
    size: 30,
    strokeWidth: 3,
    trackBarColor: rgba(0, 0, 0, 0),
    progressBarColor: $variantStyle
      ? theme.colors.palette.white
      : theme.colors.palette.black,
  };

  if (!isPostRegTimerVisible && !isTimerActive) {
    return null;
  }

  return (
    <>
      <Observer onChange={handleIntersection} rootMargin="45% 0px 0px 0px">
        <IntersectionContainer />
      </Observer>
      <CountdownTimerWrapper
        $variantStyle={$variantStyle}
        isVisible={isVisible || $variantStyle !== 'floating'}
      >
        <TimerHeader $variantStyle={$variantStyle}>
          <FormattedMessage
            id="mobile_postreg_redesign_react.offer_expiry"
            defaultMessage="Your offer expires in"
          />
        </TimerHeader>
        <TimerWrapper>
          <CountdownWrapper $variantStyle={$variantStyle} $isDisabled>
            <RadialProgressBar
              value={0}
              maxValue={1}
              {...radialProgressBarProps}
            />
            <TimeWrapper>
              <Time $variantStyle={$variantStyle}>
                <StyledTime>{padNumber(hours)}</StyledTime>
              </Time>
            </TimeWrapper>
            {$variantStyle && (
              <TimerLabel $variantStyle={$variantStyle}>
                <FormattedMessage
                  id="mobile_postreg_redesign_react.hours"
                  defaultMessage="Hours"
                />
              </TimerLabel>
            )}
          </CountdownWrapper>
          <TimerSeparator $variantStyle={$variantStyle}>:</TimerSeparator>
          <CountdownWrapper $variantStyle={$variantStyle}>
            <StyleRadialProgressBar
              value={minutes}
              maxValue={60}
              {...radialProgressBarProps}
            />
            <TimeWrapper>
              <Time $variantStyle={$variantStyle}>{padNumber(minutes)}</Time>
            </TimeWrapper>
            {$variantStyle && (
              <TimerLabel $variantStyle={$variantStyle}>
                <FormattedMessage
                  id="mobile_postreg_redesign_react.minutes"
                  defaultMessage="Minutes"
                />
              </TimerLabel>
            )}
          </CountdownWrapper>
          <TimerSeparator $variantStyle={$variantStyle}>:</TimerSeparator>
          <CountdownWrapper $variantStyle={$variantStyle}>
            <StyleRadialProgressBar
              value={seconds}
              maxValue={60}
              {...radialProgressBarProps}
            />
            <TimeWrapper>
              <Time $variantStyle={$variantStyle}>{padNumber(seconds)}</Time>
            </TimeWrapper>
            {$variantStyle && (
              <TimerLabel $variantStyle={$variantStyle}>
                <FormattedMessage
                  id="mobile_postreg_redesign_react.seconds"
                  defaultMessage="Seconds"
                />
              </TimerLabel>
            )}
          </CountdownWrapper>
        </TimerWrapper>
      </CountdownTimerWrapper>
    </>
  );
}

MobilePostRegCountdown.propTypes = {
  $variantStyle: PropTypes.bool,
};
