import React, { useEffect, useState } from 'react';

import config from 'config';
import { useRouter } from 'next/router';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import PostregCountdown from '~/shared/components/PostregCountdown';
import usePostRegCountdownEndTime from '~/shared/utils/usePostRegCountdownEndTime';
import { useCountdown } from '~/techstyle-shared/react-components';

const CountdownWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  ${({ theme }) => theme.tabsNode.countdownWrapper};
`;

// separating banner out to avoid extra rerenders caused by `useCountdown`
const HeaderBanner = ({ showPostRegTimer }) => {
  const { pathname } = useRouter();

  const { countDownEndTime } = usePostRegCountdownEndTime();
  const { isTimerActive } = useCountdown(countDownEndTime);
  const [initialTimerActive] = useState(isTimerActive);

  useEffect(() => {
    if (initialTimerActive && Date.now() > countDownEndTime) {
      window.location.reload();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [countDownEndTime]);

  // only display mounted banner if whitelabeled and user is not on
  //  homepage, otherwise, if on homepage render banner through the given nodes
  const isHomepage = pathname === '/';
  const showCountdownBanner =
    config.get('public.brand.showPostRegBanner') &&
    isTimerActive &&
    showPostRegTimer &&
    !isHomepage;

  return (
    showCountdownBanner && (
      <CountdownWrapper>
        <PostregCountdown onPostRegPage />
      </CountdownWrapper>
    )
  );
};

HeaderBanner.propTypes = {
  showPostRegTimer: PropTypes.bool,
};

export default HeaderBanner;
