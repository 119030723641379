import React, { useRef } from 'react';

import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import config from 'config';
import { useRouter } from 'next/router';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import SearchProvider from '~/search/components/SearchProvider';
import { CioSearchHistoryProvider } from '~/searchCio/hooks/useCioSearchHistory';
import BackToTop from '~/shared/components/BackToTop';
import MobilePostRegCountdown from '~/shared/components/MobilePostRegCountdown/MobilePostRegCountdown';
import { countryMessages } from '~/shared/utils/countries';
import {
  getLocalEnvFeature,
  localEnvFeatureNames,
} from '~/shared/utils/getLocalEnvFeature';
import NavElementsProvider from '~/shared/utils/NavElements';
import useBodyOnly from '~/shared/utils/useBodyOnly';
import { CioProvider } from '~/shared/utils/useCioClient';
import useIsServerSafeMobile from '~/shared/utils/useIsServerSafeMobile';
import { HeaderAttachment } from '~/techstyle-shared/react-components';
import {
  FormattedMessage,
  useSuggestedDomain,
} from '~/techstyle-shared/react-intl';
import { useDomain, useSession } from '~/techstyle-shared/redux-core';

import BannerMessage from '../BannerMessage';
import BuilderFooterRenderer from '../BuilderFooterRenderer';
import BuilderHeaderRenderer from '../BuilderHeaderRenderer';
import CanonicalLink from '../CanonicalLink';
import DefaultHead from '../DefaultHead';
import Gshop from '../Gshop';

const Wrapper = styled.div`
  position: relative;
  z-index: 0;
`;

const Body = styled.div`
  min-height: 50vh;
  position: relative;
  isolation: isolate;
`;

const UrlWrapper = styled.span`
  text-decoration: underline;
`;

function includeFooter(route, isMobile) {
  return config
    .get('public.brand.excludeFooterRoutes')
    .some((r) => !(isMobile && route.startsWith(r)));
}

const queryClient = new QueryClient();

export default function DefaultLayout({
  children,
  title,
  description,
  noHeader = false,
  noFooter = false,
  showPostRegTimer = true,
  canonicalPath = '',
  showMobilePostRegTimer = false,
  noIndex = false,
  ...props
}) {
  const { shouldRedirect, suggestedTld, geoCountry } = useSuggestedDomain();
  const { tld: currentTld } = useDomain();
  const { isLoggedIn } = useSession();
  const { pathname } = useRouter();
  const headerWrapperRef = useRef();
  const topBarWrapperRef = useRef();
  const stickyBannerRef = useRef();
  const bodyOnly = useBodyOnly();
  const isMobile = useIsServerSafeMobile();
  const isReactQueryDevtoolsEnabled = getLocalEnvFeature(
    localEnvFeatureNames.REACT_QUERY_DEVTOOLS
  );

  const headerAttachmentContainerRef = useRef();

  const showFooter =
    !bodyOnly && !noFooter && includeFooter(pathname, isMobile);
  const showHeader = !bodyOnly && !noHeader;

  const shouldSuggestGeoDomain =
    config.has('public.brand.suggestGeoDomain') &&
    config.get('public.brand.suggestGeoDomain');
  const shopExclusivelyAtSupportedCountries =
    config.has('public.brand.shopExclusivelyAtSupportedCountries') &&
    config.get('public.brand.shopExclusivelyAtSupportedCountries');
  const brandLabel =
    config.has('public.brand.label') && config.get('public.brand.label');

  let shopExclusivelyEnabled = false;

  let overrideLink;
  if (shopExclusivelyAtSupportedCountries) {
    const shopExclusivelyHasSupportedCountries =
      Object.keys(shopExclusivelyAtSupportedCountries).length > 0;
    const shopExclusivelyHasCurrentCountry =
      shopExclusivelyHasSupportedCountries
        ? Object.keys(shopExclusivelyAtSupportedCountries).includes(geoCountry)
        : false;
    const shopExclusivelyIncludesCurrentTld =
      shopExclusivelyHasSupportedCountries && shopExclusivelyHasCurrentCountry
        ? Object.keys(shopExclusivelyAtSupportedCountries[geoCountry]).includes(
            currentTld
          )
        : false;

    shopExclusivelyEnabled =
      shopExclusivelyHasSupportedCountries &&
      shopExclusivelyHasCurrentCountry &&
      shopExclusivelyIncludesCurrentTld;
    if (shopExclusivelyEnabled) {
      overrideLink =
        shopExclusivelyAtSupportedCountries[geoCountry][currentTld]
          .overrideLink;
    }
  }

  return (
    <QueryClientProvider client={queryClient}>
      {isReactQueryDevtoolsEnabled && (
        <ReactQueryDevtools initialIsOpen={false} />
      )}
      <NavElementsProvider
        refs={{
          headerWrapperRef,
          topBarWrapperRef,
          headerAttachmentContainerRef,
          stickyBannerRef,
        }}
      >
        <HeaderAttachment.Provider>
          <CioProvider>
            <DefaultHead
              title={title}
              description={description}
              noIndex={noIndex}
            />
            <CanonicalLink canonicalPath={canonicalPath} />
            <Wrapper {...props}>
              <Gshop />
              <HeaderAttachment.Provider>
                {!bodyOnly && (
                  <SearchProvider>
                    {shouldSuggestGeoDomain &&
                      shouldRedirect &&
                      !isLoggedIn && (
                        <BannerMessage cookieName="shown_us_ca_lightbox">
                          <FormattedMessage
                            id="site_login.help_us_serve"
                            defaultMessage="Help us serve you better by shopping on the site of your home country."
                          />{' '}
                          <FormattedMessage
                            id="site_login.proceed_to"
                            defaultMessage="PROCEED TO {1}"
                            values={{
                              1: `FABLETICS${suggestedTld.toUpperCase()}`,
                            }}
                          />
                        </BannerMessage>
                      )}

                    {shopExclusivelyEnabled && (
                      <BannerMessage
                        cookieName="shown_shop_exclusive_banner"
                        overrideLink={overrideLink}
                      >
                        <FormattedMessage
                          id="nav_banners_yitty.can_redirect"
                          defaultMessage="Hello {1}! You can now shop {2} exclusively at"
                          values={{
                            1: `${countryMessages[geoCountry].defaultMessage}`,
                            2: `${brandLabel.toUpperCase()}`,
                          }}
                        />{' '}
                        <UrlWrapper>
                          {shopExclusivelyAtSupportedCountries[geoCountry][
                            currentTld
                          ].overrideLink ? (
                            <>
                              {
                                shopExclusivelyAtSupportedCountries[geoCountry][
                                  currentTld
                                ].overrideLink
                              }
                            </>
                          ) : (
                            <>
                              {
                                shopExclusivelyAtSupportedCountries[geoCountry][
                                  currentTld
                                ].redirectToDomain
                              }
                              {suggestedTld}
                              {
                                shopExclusivelyAtSupportedCountries[geoCountry][
                                  currentTld
                                ].overrideLink
                              }
                            </>
                          )}
                        </UrlWrapper>
                      </BannerMessage>
                    )}
                    {showHeader && (
                      <CioSearchHistoryProvider>
                        <BuilderHeaderRenderer
                          showPostRegTimer={showPostRegTimer}
                        />
                      </CioSearchHistoryProvider>
                    )}
                  </SearchProvider>
                )}
                <Body>{children}</Body>
                {showFooter && <BuilderFooterRenderer />}
              </HeaderAttachment.Provider>
            </Wrapper>
            {showFooter && <BackToTop />}
            {showMobilePostRegTimer && (
              <MobilePostRegCountdown $variantStyle="floating" />
            )}
          </CioProvider>
        </HeaderAttachment.Provider>
      </NavElementsProvider>
    </QueryClientProvider>
  );
}

DefaultLayout.propTypes = {
  canonicalPath: PropTypes.string,
  children: PropTypes.node,
  description: DefaultHead.propTypes.description,
  noFooter: PropTypes.bool,
  noHeader: PropTypes.bool,
  noIndex: PropTypes.bool,
  showMobilePostRegTimer: PropTypes.bool,
  showPostRegTimer: PropTypes.bool,
  title: DefaultHead.propTypes.title,
};
