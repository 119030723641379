import { useDomain } from '~/techstyle-shared/redux-core';

import getLocalizedPath from './getLocalizedPath';

// TODO: figure out way to localize the parameterized URLs
export default function useLocalizedPath(path, options = { tld: null }) {
  const domain = useDomain();
  const tld = options.tld || domain.tld;
  return getLocalizedPath({ path, tld });
}
