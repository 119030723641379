import React from 'react';

import PropTypes from 'prop-types';

import { AssetContainer } from '~/techstyle-shared/react-assets';

const AssetContainerLogo = ({ name }) => {
  if (!name) {
    return null;
  }

  return <AssetContainer name={name} />;
};

AssetContainerLogo.propTypes = {
  name: PropTypes.string,
};

export default AssetContainerLogo;
