import React, { useCallback, useContext, useMemo, useState } from 'react';

import PropTypes from 'prop-types';

const Context = React.createContext();

export function useSearchContext() {
  const context = useContext(Context);
  return context || {};
}

export default function SearchProvider({ children }) {
  const [isActive, setActive] = useState(false);

  const toggleActive = useCallback((value = (isActive) => !isActive) => {
    return setActive(value);
  }, []);

  const value = useMemo(
    () => ({ isActive, toggleActive }),
    [isActive, toggleActive]
  );

  return <Context.Provider value={value}>{children}</Context.Provider>;
}

SearchProvider.propTypes = { children: PropTypes.node };

SearchProvider.Context = Context;
