import { useCallback, useEffect } from 'react';

import { useRouter } from 'next/router';
import { useDispatch } from 'react-redux';

import { trackCognigyEvent } from '~/shared/actions';
import {
  CUSTOMER_DETAILS_MAP,
  SEGMENT_EVENTS_MAP,
} from '~/shared/constants/cognigy';
import { useAccountActions } from '~/techstyle-shared/react-accounts';

function useCognigyEvents() {
  const dispatch = useDispatch();
  const accountActions = useAccountActions();
  const route = useRouter();

  const handleEvent = useCallback(
    (event) => {
      if (!event || !event.type) {
        return;
      }

      const customerDetailName = CUSTOMER_DETAILS_MAP[event.type];
      if (customerDetailName) {
        accountActions.updateCustomerDetail({
          name: customerDetailName,
          value: route.pathname,
        });
      }

      const segmentEventName = SEGMENT_EVENTS_MAP[event.type];
      const hasEventText = event.payload?.text;
      if (segmentEventName && hasEventText) {
        dispatch(
          trackCognigyEvent({
            name: segmentEventName,
            route: route.pathname,
            ...event.payload,
          })
        );
      }
    },
    [accountActions, dispatch, route.pathname]
  );

  useEffect(() => {
    let isMounted = true;
    if (!window?.webchat || !isMounted) {
      return;
    }

    window.webchat.registerAnalyticsService((event) => {
      handleEvent(event);
    });

    return () => {
      window.webchat.analytics.removeAllListeners();
      isMounted = false;
    };
  }, [handleEvent]);
}

export default useCognigyEvents;
