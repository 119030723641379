import React from 'react';

import config from 'config';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { FormattedMessage } from '~/techstyle-shared/react-intl';

const DynamicFormattedMessage = FormattedMessage;

const CompanyWrapper = styled.div`
  line-height: 1.5;
`;

const brandName = config.get('public.brand.label');

export default function CompanyInfo({ className }) {
  return (
    <CompanyWrapper className={className}>
      <DynamicFormattedMessage
        id="global_cta.company_name"
        defaultMessage={brandName}
      />{' '}
      <FormattedMessage
        id="global_cta.company_address"
        defaultMessage="800 Apollo St. El Segundo, CA 90245"
      />
    </CompanyWrapper>
  );
}

CompanyInfo.propTypes = {
  className: PropTypes.string,
};
