import React, { useState } from 'react';

import PropTypes from 'prop-types';
import { FaChevronRight } from 'react-icons/fa';
import styled, { css } from 'styled-components';

import NavLink from '~/shared/components/NavLink';
import getAutoTag from '~/shared/utils/getAutoTag';
import { isLocale } from '~/shared/utils/helpers';
import shouldShowPrivacyAct from '~/shared/utils/shouldShowPrivacyAct';
import {
  Button,
  FlagIcon,
  desktop,
  mobile,
  useBreakpoint,
} from '~/techstyle-shared/react-components';
import { useGeoState } from '~/techstyle-shared/react-intl';
import { NavContainer } from '~/techstyle-shared/react-navigation';
import { useDomain, useSession } from '~/techstyle-shared/redux-core';

import { useFooterNavHandle } from '../../utils/navHandles';
import AdaWidget from '../AdaWidget/AdaWidget';
import { COUNTRIES as countries } from '../CountryMenu';
import OneTrustLink from '../OneTrustLink';

const UnstyledList = styled.ul`
  list-style: none outside none;
  margin: 0;
  padding: 0;
`;

const UnstyledListItem = styled.li`
  list-style: none outside none;
  margin: 0;
  padding: 0;
`;

const Column = styled(UnstyledListItem)`
  flex: 0 1 auto;
`;

const SectionsContainer = styled(UnstyledList)``;

const Columns = styled(UnstyledList)`
  list-style: none;
  max-width: 1280px;
  margin-left: auto;
  margin-right: auto;
  text-align: left;

  ${desktop`
    display: flex;
    justify-content: space-between;
    padding: 0 10%;
  `}

  ${({ theme }) => {
    if (
      theme.footerStyles.columnsStyle &&
      typeof theme.footerStyles.columnsStyle === 'function'
    ) {
      // see theme.footerStyles.columnsStyle for explanation
      return theme.footerStyles.columnsStyle(Column, SectionsContainer);
    }
  }}
`;

const SectionMenu = styled(UnstyledList)`
  ${mobile`
    padding-bottom: 1.25em;
  `}
`;

const SectionIcon = styled.span`
  ${desktop`display: none;`}
  position: absolute;
  right: 0;
  top: -5px;
  transform: translateY(100%) scale(0.75);
  > svg {
    transform: rotate(90deg);
    transition: transform 0.25s ease;
  }

  ${({ $isClosed }) =>
    !$isClosed &&
    css`
      > svg {
        transform: rotate(270deg);
      }
    `}

  ${({ $isLinkHeader }) =>
    $isLinkHeader &&
    css`
      display: none;
    `}
`;

const Section = styled.div`
  ${({ theme }) => theme.footerStyles.sectionStyle}

  ${(props) =>
    props.isClosed &&
    mobile`
    & > ${SectionMenu} {
      display: none;
    }
  `}

  ${(props) =>
    !props.isClosed &&
    `
    > svg {
      transform: rotate(-90deg);
    }
  `}
`;

const SectionTitleButton = styled(Button).attrs({
  variant: 'unstyled',
})`
  -webkit-tap-highlight-color: transparent;
  width: 100%;

  span {
    justify-content: flex-start;
  }
`;

const SectionTitle = styled.h3`
  ${({ theme }) => theme.footerStyles.sectionTitleStyle}
`;

const SectionMenuItemStyle = css`
  padding: 4px 0;
`;

const SectionMenuItem = styled(UnstyledListItem)`
  ${SectionMenuItemStyle}

  ${mobile`
    text-transform: uppercase;
    padding: 10px 0 10px 30px;
    font-size: 12px;
  `}
`;

const SectionMenuLink = styled(NavLink)`
  ${({ theme }) => theme.footerStyles.sectionMenuLinkStyle}
`;

const CountryFlagIcon = styled(FlagIcon)`
  width: 18px;
  height: 13px;
  margin-right: 0.5em;
`;

const CountryLink = styled(SectionMenuLink)`
  display: inline-flex;
  align-items: center;
`;

const SectionWrapper = styled.li`
  position: relative;
  ${desktop`
    margin-bottom: 3em;

    ${SectionsContainer}:last-child &:last-child {
      margin-bottom: 0;
    }
  `}
`;

const CcpaLink = styled(CountryLink)`
  ${mobile`
		:link {
			display: inline-flex;
			align-items: center;
		}
	`}
`;

const PrivacyIcon = styled.div`
  margin-left: 6px;
  height: 14px;
  width: 36px;
  background-image: url(/optOutIcon.svg);
  background-repeat: no-repeat;
  background-size: contain;
`;

const ITEM_KEYS = {
  REGION: 'region',
  CCPA: 'ccpa',
  FL_ENABLE_ADA: 'FL_Enable_ADA',
  MANAGE_COOKIE_SETTINGS: 'Manage_Cookie_Settings',
  MEMBER_SERVICES_CANCEL: 'Member_services_cancel',
};

function getFooterAutoTag(navItem) {
  return getAutoTag(navItem, 'footer');
}

function ToggleableSection({ children, section, target }) {
  const [isClosed, setIsClosed] = useState(true);
  const { isMobile } = useBreakpoint();

  const hasChildren =
    section?.navItems?.length || section?.itemKey === ITEM_KEYS.REGION;
  const toggle = (event) => {
    if (isMobile && hasChildren) {
      event.preventDefault();
      setIsClosed(!isClosed);
    }
  };

  return (
    <SectionWrapper>
      {React.cloneElement(target, { onClick: toggle })}
      <Section isClosed={isClosed}>
        {children}
        {hasChildren && (
          <SectionIcon $isLinkHeader={!hasChildren} $isClosed={isClosed}>
            <FaChevronRight size={18} />
          </SectionIcon>
        )}
      </Section>
    </SectionWrapper>
  );
}

ToggleableSection.propTypes = {
  children: PropTypes.node,
  section: PropTypes.object,
  target: PropTypes.node,
};

export default function NavContainerWrapper() {
  const domain = useDomain();
  const { membershipStateList, isLoggedIn } = useSession();
  const showPrivacyAct = shouldShowPrivacyAct(membershipStateList);
  const geoState = useGeoState();
  const isPrivacyActStateByIp = (geoStatValue) => {
    const privacyActStates = ['CA', 'CO', 'CT'];
    return privacyActStates.includes(geoStatValue);
  };
  const isLoggedOutDE = isLocale(['DE'], domain.tld) && !isLoggedIn;

  const countryList = countries.map(({ code, label, tld }) => {
    return (
      <SectionMenuItem key={code}>
        <CountryLink
          url={domain.getDomainRedirect({ tld })}
          data-autotag={`footer_${code}`}
          label={label}
        >
          <CountryFlagIcon country={code} />
        </CountryLink>
      </SectionMenuItem>
    );
  });

  const footerNavHandle = useFooterNavHandle();

  return (
    <NavContainer handle={footerNavHandle}>
      {({ data }) => {
        if (!data) {
          return null;
        }

        return (
          <Columns>
            {data?.navItems?.map((column) => (
              <Column key={column.id}>
                <SectionsContainer>
                  {column?.navItems?.map((section) => (
                    <ToggleableSection
                      key={section.id}
                      section={section}
                      target={
                        <SectionTitleButton>
                          <SectionTitle>
                            <SectionMenuLink
                              {...section}
                              $isLinkHeader={!section.navItems}
                              data-autotag={getFooterAutoTag(section)}
                            />
                          </SectionTitle>
                        </SectionTitleButton>
                      }
                    >
                      <SectionMenu>
                        {section?.itemKey === ITEM_KEYS.REGION && countryList}
                        {section?.navItems?.map((navItem) => {
                          if (navItem.itemKey === ITEM_KEYS.CCPA) {
                            if (
                              showPrivacyAct ||
                              isPrivacyActStateByIp(geoState)
                            ) {
                              return (
                                <SectionMenuItem key={navItem.id}>
                                  <CcpaLink
                                    {...navItem}
                                    data-autotag={getFooterAutoTag(navItem)}
                                    appendChildren
                                  >
                                    <PrivacyIcon />
                                  </CcpaLink>
                                </SectionMenuItem>
                              );
                            }
                          }
                          if (
                            navItem.itemKey === ITEM_KEYS.CCPA &&
                            !(isPrivacyActStateByIp(geoState) || showPrivacyAct)
                          ) {
                            return null;
                          } else if (
                            navItem.itemKey === ITEM_KEYS.FL_ENABLE_ADA
                          ) {
                            if (domain.tld !== '.com') {
                              return null; // bail out if not US
                            }
                            return (
                              <SectionMenuItem key={navItem.id}>
                                <AdaWidget
                                  data-ga-label={navItem.gaLabel}
                                  data-autotag={getFooterAutoTag(navItem)}
                                >
                                  {navItem.label}
                                </AdaWidget>
                              </SectionMenuItem>
                            );
                          } else if (
                            navItem.itemKey === ITEM_KEYS.MANAGE_COOKIE_SETTINGS
                          ) {
                            return (
                              <SectionMenuItem key={navItem.id}>
                                <OneTrustLink
                                  navItem={navItem}
                                  autotag={getFooterAutoTag(navItem)}
                                />
                              </SectionMenuItem>
                            );
                          } else if (
                            navItem.itemKey === ITEM_KEYS.MEMBER_SERVICES_CANCEL
                          ) {
                            // TODO: CLEAN UP ONCE SEGMENTATION IS FIX FOR NAVIGATIONIZER
                            // TICKET REF:FLR-1161
                            if (isLoggedOutDE) {
                              return (
                                <SectionMenuItem key={navItem.id}>
                                  <SectionMenuLink
                                    {...navItem}
                                    data-autotag={getFooterAutoTag(navItem)}
                                  />
                                </SectionMenuItem>
                              );
                            }
                            return null;
                          }
                          return (
                            <SectionMenuItem key={navItem.id}>
                              <SectionMenuLink
                                {...navItem}
                                data-autotag={getFooterAutoTag(navItem)}
                              />
                            </SectionMenuItem>
                          );
                        })}
                      </SectionMenu>
                    </ToggleableSection>
                  ))}
                </SectionsContainer>
              </Column>
            ))}
          </Columns>
        );
      }}
    </NavContainer>
  );
}
