import { useBreakpoint } from '~/techstyle-shared/react-components';
import { useHydrationStatus } from '~/techstyle-shared/redux-core';

import { useDeviceDetection } from '../../context/deviceDetection';

/**
 * Allows us to make a tentative determination of whether the device would be
 * considered a "mobile" via the user agent on the SSR pass, but updates to
 * using the actual device width on the client side.
 *
 * NOTE: for most of cases, we should continue to use the `useBreakpoint`
 * hook directly. this hook is only for cases where we need to make a
 * strong determination on the server side.
 *
 * @returns {boolean} True if the environment is safe for server-side rendering on mobile devices, false otherwise.
 */
export default function useIsServerSafeMobile(): boolean {
  const { isMobile: isMobileBreakpoint } = useBreakpoint();
  const deviceDetection = useDeviceDetection();
  const isHydrated = useHydrationStatus();

  return isHydrated ? isMobileBreakpoint : deviceDetection?.isMobileOnly;
}
