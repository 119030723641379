import config from 'config';

export const getCognigyConfig = (locale) => {
  const cognigyConfig = config.get('public.cognigyWebChat');
  const localeConfig = cognigyConfig[locale] || cognigyConfig.default;

  return localeConfig;
};

export const getCustomerDetails = (customer) => {
  const fullName = `${customer?.firstName} ${customer?.lastName}`;
  const id = customer?.id;

  const customerDetail = {
    name: fullName,
    customerId: id,
  };

  return customerDetail;
};
