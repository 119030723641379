import { useEffect, useState } from 'react';

import config from 'config';

import { useCustomer, useMembership } from '~/techstyle-shared/react-accounts';
import { useDomain, useCookies } from '~/techstyle-shared/redux-core';

import featureNames from '../constants/featureFlags';

import { isLocale } from './helpers';
import useHasFeature from './useHasFeature';

const useGenesysWebChatAction = () => {
  const genensysFeatureEnabler = useHasFeature(featureNames.GENESYS_WEB_CHAT);
  const { email } = useCustomer();
  const { customerId } = useMembership();
  const { tld } = useDomain();

  const deploymentIds = config.get('public.genesysWebChatDeploymentId');
  const deploymentId = isLocale(['UK', 'FR', 'DE', 'ES'], tld)
    ? deploymentIds[tld]
    : deploymentIds.default;
  const GENESYS_COOKIE_NAME = 'FL-GENERAL-GENESYS_MESSENGER_ALWAYSSHOW';
  const [cookies, setCookie] = useCookies([GENESYS_COOKIE_NAME]);
  const expireGenesysCookie = new Date(new Date().getTime() + 180 * 60 * 1000);
  const showMsgAlways = cookies[GENESYS_COOKIE_NAME];
  const [showIconOnMobileApp, setShowIconOnMobileApp] = useState(false);

  useEffect(() => {
    const url = new URL(window.location);
    const isProfilePage = url.pathname.includes('/account/profile');
    const showMessengerByMobileApp =
      url.searchParams.get('fromMobileApp') && isProfilePage;

    if (window?.Genesys) {
      if (customerId && email) {
        window.Genesys('command', 'Database.set', {
          messaging: {
            customAttributes: {
              customer_id: customerId,
              email: email,
            },
          },
        });
      }

      if (!cookies[GENESYS_COOKIE_NAME] && showMessengerByMobileApp) {
        setShowIconOnMobileApp(true);
        setCookie([GENESYS_COOKIE_NAME], true, {
          expires: expireGenesysCookie,
        });
      }

      if (showMsgAlways || showMessengerByMobileApp) {
        window.Genesys('subscribe', 'Launcher.ready', function () {
          window.Genesys('command', 'Launcher.show');
          if (showMessengerByMobileApp) {
            window.Genesys('command', 'Messenger.open', {});
          }
        });
      }
    }
  }, [
    setShowIconOnMobileApp,
    cookies,
    customerId,
    email,
    expireGenesysCookie,
    setCookie,
    showMsgAlways,
  ]);

  const genesysHandler = () => {
    window.Genesys(
      'command',
      'Messenger.open',
      {},
      function (o) {
        if (!cookies[GENESYS_COOKIE_NAME]) {
          setCookie([GENESYS_COOKIE_NAME], true, {
            expires: expireGenesysCookie,
          });
        }
      }, // if resolved
      function (o) {
        // if rejected
        window.Genesys('command', 'Messenger.close');
      }
    );
  };

  return {
    deploymentId,
    genensysFeatureEnabler,
    genesysHandler,
    showIconOnMobileApp,
    showMsgAlways,
  };
};

export default useGenesysWebChatAction;
