import React from 'react';

import { FormattedMessage } from '~/techstyle-shared/react-intl';

export default function Copyright(props) {
  const currentYear = new Date().getFullYear();
  return (
    <a href="https://www.techstyle.com/" {...props}>
      <FormattedMessage
        id="site_navigation.copyright"
        defaultMessage="&copy; {1} TechStyle Fashion Group or its affiliates"
        values={{ 1: currentYear, year: currentYear }}
      />
    </a>
  );
}
