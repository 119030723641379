import { useMemo, useState, useEffect } from 'react';

import { ONE_HOUR } from '~/shared/utils/durations';
import { useMembership } from '~/techstyle-shared/react-accounts';
import { parseDate } from '~/techstyle-shared/redux-core';

export default function usePostRegCountdownEndTime() {
  const { dateTimeAdded: dateTimeAddedString, onSiteMembershipExperience } =
    useMembership();

  const dateTimeAdded = useMemo(() => {
    return dateTimeAddedString ? parseDate(dateTimeAddedString) : null;
  }, [dateTimeAddedString]);

  const [extension, setExtension] = useState();

  useEffect(() => {
    setExtension(+window.localStorage.getItem('postRegCountDownExtension'));
  }, []);

  const startTime = useMemo(() => {
    return onSiteMembershipExperience
      ? parseDate(onSiteMembershipExperience)
      : dateTimeAdded;
  }, [dateTimeAdded, onSiteMembershipExperience]);

  const countDownEndTime = useMemo(() => {
    if (extension && startTime) {
      return startTime.getTime() + ONE_HOUR + extension;
    } else if (startTime) {
      return startTime.getTime() + ONE_HOUR;
    } else {
      return null;
    }
  }, [extension, startTime]);

  const extendCountDownEndTime = (now = Date.now()) => {
    const _now = now || Date.now();
    // For how long has the countdown expired from now?
    const expiredDuration = _now - countDownEndTime;
    // New countdown extension should be equal to:
    //    expired duration + an additional hour
    //        => new end time is an hour from now
    window.localStorage.setItem(
      'postRegCountDownExtension',
      expiredDuration + ONE_HOUR
    );
    setExtension(expiredDuration + ONE_HOUR);
  };

  return { countDownEndTime, extendCountDownEndTime };
}
