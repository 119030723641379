import React, { useEffect } from 'react';

import PropTypes from 'prop-types';
import styled from 'styled-components';

import LoadingSpinner from '~/shared/components/LoadingSpinner';
import { getWidgetBtnStyle } from '~/shared/constants/accessibilityWidget';
import {
  EqualWebButton,
  LoadingStatus,
  useBreakpoint,
} from '~/techstyle-shared/react-components';

const AdaButton = styled(EqualWebButton)`
  cursor: pointer;
  padding: 0;
  margin: 0;
  border: 0;
  ${({ theme }) => theme.footerStyles.sectionMenuLinkStyle}
  ${({ theme }) => theme.footerStyles.adaWidgetButtonCopy}
`;

const LoadingContainer = styled.span`
  position: absolute;
  top: 0;
  bottom: 0;
  right: 100%;
  height: 100%;
  padding-right: 4px;
`;

export default function AdaWidget({ children, ...rest }) {
  const { isMobile } = useBreakpoint();

  useEffect(() => {
    if (window?.interdeal?.btnStyle) {
      window.interdeal.btnStyle = getWidgetBtnStyle(isMobile);
    }
  }, [isMobile]);

  const handleTabPress = (event) => {
    const parentForm = event.target.closest('form');
    const isWithinForm = Boolean(parentForm);
    return !isWithinForm;
  };

  return (
    <AdaButton
      variant="unstyled"
      loadSnippetOnTabPress={handleTabPress}
      {...rest}
    >
      {children}
      <LoadingStatus.LoadingIndicator>
        <LoadingContainer>
          <LoadingSpinner />
        </LoadingContainer>
      </LoadingStatus.LoadingIndicator>
    </AdaButton>
  );
}

AdaWidget.propTypes = {
  children: PropTypes.node,
};
