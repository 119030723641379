import React from 'react';

import config from 'config';
import PropTypes from 'prop-types';
import { FaFacebookSquare, FaTiktok } from 'react-icons/fa';
import { FaXTwitter } from 'react-icons/fa6';
import styled from 'styled-components';

import {
  useBreakpoint,
  SocialIcon,
  mobile,
} from '~/techstyle-shared/react-components';
import { FormattedMessage, useRegion } from '~/techstyle-shared/react-intl';
import { useDomain } from '~/techstyle-shared/redux-core';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  color: ${({ theme: { colors } }) => colors.white};
`;

const Label = styled.div`
  align-self: flex-start;
  font-size: 14px;
  font-weight: 600;
  margin-bottom: ${({
    theme: {
      spacing: { sizes },
    },
  }) => sizes.pixels.xs}px;
`;

const Icons = styled.div`
  display: flex;
  gap: ${({
    theme: {
      spacing: { sizes },
    },
  }) => sizes.pixels.m}px;

  ${mobile`
    gap: 0;
    justify-content: space-around;
  `}
`;

function SocialMediaIcons({ hideLabel = false, container = 'footer' }) {
  const { isMobile } = useBreakpoint();
  const region = useRegion();
  const { tld } = useDomain();

  const socialMediaUrls = config.get('public.brand.socialMediaUrls')[region][
    tld
  ];
  const iconSize = isMobile ? 26 : 24;

  return (
    <Container>
      {!hideLabel && (
        <Label>
          <FormattedMessage
            id="site_navigation.follow_us_on_social"
            defaultMessage="Follow us on Social!"
          />
        </Label>
      )}
      <Icons>
        {socialMediaUrls.instagram && (
          <SocialIcon
            size={iconSize}
            platform="instagram"
            href={socialMediaUrls.instagram}
            data-autotag={`${container}_instagram`}
          />
        )}
        {socialMediaUrls.tiktok && (
          <SocialIcon
            size={iconSize}
            icon={FaTiktok}
            platform="tiktok"
            label="Tiktok"
            href={socialMediaUrls.tiktok}
            data-autotag={`${container}_tiktok`}
          />
        )}
        {socialMediaUrls.facebook && (
          <SocialIcon
            size={iconSize}
            icon={FaFacebookSquare}
            platform="facebook"
            href={socialMediaUrls.facebook}
            data-autotag={`${container}_facebook`}
          />
        )}
        {socialMediaUrls.youtube && (
          <SocialIcon
            size={iconSize}
            platform="youtube"
            href={socialMediaUrls.youtube}
            data-autotag={`${container}_youtube`}
          />
        )}
        {socialMediaUrls.pinterest && (
          <SocialIcon
            size={iconSize}
            platform="pinterest"
            href={socialMediaUrls.pinterest}
            data-autotag={`${container}_pinterest`}
          />
        )}
        {socialMediaUrls.twitter && (
          <SocialIcon
            size={iconSize}
            icon={FaXTwitter}
            platform="twitter"
            href={socialMediaUrls.twitter}
            data-autotag={`${container}_twitter`}
          />
        )}
      </Icons>
    </Container>
  );
}

SocialMediaIcons.propTypes = {
  container: PropTypes.string,
  hideLabel: PropTypes.bool,
};

export default SocialMediaIcons;
