export const localEnvFeatureNames = {
  REACT_QUERY_DEVTOOLS: 'REACT_QUERY_DEVTOOLS',
};

export const getLocalEnvFeature = (flag) => {
  switch (flag) {
    case localEnvFeatureNames.REACT_QUERY_DEVTOOLS:
      return process.env.NEXT_PUBLIC_ENABLE_REACT_QUERY_DEVTOOLS === 'true';
    default:
      return false;
  }
};
