import React, { useMemo } from 'react';

import PropTypes from 'prop-types';
import styled from 'styled-components';

import { CountryMenu as BaseCountryMenu } from '~/techstyle-shared/react-components';
import { FormattedMessage } from '~/techstyle-shared/react-intl';
import { useDomain } from '~/techstyle-shared/redux-core';

const StyledCountryMenu = styled(BaseCountryMenu)`
  [data-country-menu-disclosure] {
    padding: 0;
  }
  [data-country-menu] {
    background: none;
  }
  [data-country-menu-list] {
    margin-top: 15px;
    display: block;
    min-width: 180px;
    border: 1px solid ${({ theme }) => theme.colors.borderLight};
    border-top-width: 0;
    font-size: 14px;
    font-weight: 600;
    line-height: 1;
    white-space: nowrap;
    text-transform: capitalize;
  }
  [data-country-menu-list-item] {
    border-top: 1px solid ${({ theme }) => theme.colors.borderLight};
  }
  [data-menu-item] {
    background: white;
    padding: 1em;
    color: ${({ theme }) => theme.colors.textDeemphasized};

    &:hover {
      color: ${({ theme }) => theme.colors.textDefault};
      text-decoration: underline;
    }
  }
  [data-country-menu-active='true'] [data-menu-item] {
    color: ${({ theme }) => theme.colors.textDefault};
  }

  [role='menu'] {
    z-index: 3;
  }
`;

export const COUNTRIES = [
  {
    autoTag: 'top_nav_country_flag_US',
    code: 'US',
    label: (
      <FormattedMessage
        id="global_cta.locale_selector_US"
        defaultMessage="United States"
      />
    ),
    tld: '.com',
  },
  {
    autoTag: 'top_nav_country_flag_CA',
    code: 'CA',
    label: (
      <FormattedMessage
        id="global_cta.locale_selector_CA"
        defaultMessage="Canada"
      />
    ),
    tld: '.ca',
  },
  {
    autoTag: 'top_nav_country_flag_DE',
    code: 'DE',
    label: (
      <FormattedMessage
        id="global_cta.locale_selector_DE"
        defaultMessage="Deutschland"
      />
    ),
    tld: '.de',
  },
  {
    autoTag: 'top_nav_country_flag_ES',
    code: 'ES',
    label: (
      <FormattedMessage
        id="global_cta.locale_selector_ES"
        defaultMessage="España"
      />
    ),
    tld: '.es',
  },
  {
    autoTag: 'top_nav_country_flag_FR',
    code: 'FR',
    label: (
      <FormattedMessage
        id="global_cta.locale_selector_FR"
        defaultMessage="France"
      />
    ),
    tld: '.fr',
  },
  {
    autoTag: 'top_nav_country_flag_GB',
    code: 'GB',
    label: (
      <FormattedMessage
        id="global_cta.locale_selector_GB"
        defaultMessage="United Kingdom"
      />
    ),
    tld: '.co.uk',
  },
  {
    autoTag: 'top_nav_country_flag_NL',
    code: 'NL',
    label: (
      <FormattedMessage
        id="global_cta.locale_selector_NL"
        defaultMessage="Nederland"
      />
    ),
    tld: '.nl',
  },
  {
    autoTag: 'top_nav_country_flag_SE',
    code: 'SE',
    label: (
      <FormattedMessage
        id="global_cta.locale_selector_SE"
        defaultMessage="Sverige"
      />
    ),
    tld: '.se',
  },
  {
    autoTag: 'top_nav_country_flag_DK',
    code: 'DK',
    label: (
      <FormattedMessage
        id="global_cta.locale_selector_DK"
        defaultMessage="Danmark"
      />
    ),
    tld: '.dk',
  },
  {
    autoTag: 'top_nav_country_flag_EU',
    code: 'EU',
    label: (
      <FormattedMessage
        id="global_cta.locale_selector_EU"
        defaultMessage="Europe"
      />
    ),
    tld: '.eu',
  },
];

export default function CountryMenu(props) {
  const domain = useDomain();
  const menuState = useMemo(() => {
    let value = null;

    const countries = COUNTRIES.map((country) => {
      if (country.tld === domain.tld) {
        value = country.code;
      }

      return {
        ...country,
        href: domain.getDomainRedirect({ tld: country.tld }),
      };
    });

    return { value, countries };
  }, [domain]);

  return (
    <StyledCountryMenu
      countries={menuState.countries ?? []}
      value={menuState.value}
      {...props}
    />
  );
}

CountryMenu.propTypes = {
  /**
   * Enable to render the active country at the top of the list.
   */
  activeFirst: PropTypes.bool,
  /**
   * Sets the position of the flag relative to the label.
   */
  alignIcon: PropTypes.oneOf(['left', 'right']).isRequired,
  /**
   * Autotag value to add to the active country flag icon.
   */
  buttonAutoTag: PropTypes.string,
  /**
   * Class name(s) to apply to the container element.
   */
  className: PropTypes.string,
  /**
   * Array of country objects
   */
  countries: PropTypes.arrayOf(
    PropTypes.shape({
      autoTag: PropTypes.string,
      code: PropTypes.string,
      disabled: PropTypes.bool,
      focusable: PropTypes.bool,
      href: PropTypes.string,
      label: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.element,
        PropTypes.func,
      ]),
    })
  ),
  /**
   * The label to apply to the MenuDisclosure element.
   */
  disclosureLabel: PropTypes.string,
  /**
   * Whether to split the active and inactive items into two separate MenuGroup
   * elements. Only meaningful when `activeFirst` is true.
   */
  groupInactive: PropTypes.bool,
  /**
   * The content to render in between the active item and the inactive items.
   * Only meaningful when both `activeFirst` and `groupInactive` are true.
   */
  groupSeparator: PropTypes.node,
  /**
   * Enable to display a caret next to the flag icon (see JustFab)
   */
  hasCaret: PropTypes.bool,
  /**
   * Sets the width of the icon.
   */
  iconSize: PropTypes.string,
  /**
   * The autotag value to add to the flags menu.
   */
  menuAutoTag: PropTypes.string,
  /**
   * The `aria-label` to apply to the Menu element.
   */
  menuLabel: PropTypes.string,
  /**
   * (optional) onChange callback
   */
  onChange: PropTypes.func,
  /**
   * (optional) onToggle callback
   */
  onToggle: PropTypes.func,
  /**
   * Determines how the menu "open" state is triggered.
   */
  openEvent: PropTypes.oneOf(['hover', 'click']).isRequired,
  /**
   * The menu placement relative to the disclosure element.
   * See: https://reakit.io/docs/menu/
   */
  placement: PropTypes.string,
  /**
   * Sets the currently active country.
   */
  value: PropTypes.string,
};

CountryMenu.defaultProps = {
  activeFirst: true,
  alignIcon: 'left',
  disclosureLabel: 'Select country',
  groupInactive: false,
  hasCaret: false,
  iconSize: '28px',
  menuLabel: 'Countries',
  openEvent: 'click',
  placement: 'auto-end',
};
