import useHasFeature from '~/shared/utils/useHasFeature';
import { useMembership } from '~/techstyle-shared/react-accounts';
import { useCountdown } from '~/techstyle-shared/react-components';

import featureNames from '../constants/featureFlags';

import usePostRegCountdownEndTime from './usePostRegCountdownEndTime';

export default function usePostRegTimer() {
  const { isLead, isPaygo, isDowngraded } = useMembership();
  const { countDownEndTime } = usePostRegCountdownEndTime();
  const { isTimerActive } = useCountdown(countDownEndTime);
  const isEnabled = useHasFeature(featureNames.POSTREG_TIMER);

  const isShowPostRegTimer =
    isEnabled && isTimerActive && (isLead || isPaygo) && !isDowngraded;

  return isShowPostRegTimer;
}
