import React from 'react';

import styled from 'styled-components';

import { FormattedMessage } from '~/techstyle-shared/react-intl';

const Wrapper = styled.div`
  ${({ theme }) => theme.footerStyles.freeShippingDetailsStyle};
`;
export default function FooterFreeShippingDetails() {
  return (
    <Wrapper data-autotag="footer_free_shipping_text">
      <FormattedMessage
        id="site_navigation.free_shipping_details_visitor"
        defaultMessage="*Free Shipping on all orders over $49.95 in the contiguous U.S. Free Exchanges."
      />
    </Wrapper>
  );
}
