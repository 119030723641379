import React from 'react';

import { DISABLE_WIDGET_TOGGLE } from '~/shared/constants/cognigy';
import {
  getCustomerDetails,
  getCognigyConfig,
} from '~/shared/utils/cognigyUtils';
import useCognigyEvents from '~/shared/utils/useCognigyEvents';
import useCognigyWebchatAction from '~/shared/utils/useCognigyWebchatAction';
import { useCustomer } from '~/techstyle-shared/react-accounts';
import { CognigyWebChatSnippet } from '~/techstyle-shared/react-marketing';
import { useDomain } from '~/techstyle-shared/redux-core';

export default function CognigyWebChatScript() {
  const { tld } = useDomain();
  const customer = useCustomer();
  const { buCode, cogRegion, cognigyEndpointKey } = getCognigyConfig(tld);
  const customerDetail = getCustomerDetails(customer);

  useCognigyWebchatAction();
  useCognigyEvents();

  return (
    <>
      <CognigyWebChatSnippet
        buCode={buCode}
        cogRegion={cogRegion}
        cognigyEndpointKey={cognigyEndpointKey}
        customerDetail={customerDetail}
        options={DISABLE_WIDGET_TOGGLE}
      />
    </>
  );
}
