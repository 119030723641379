import React from 'react';

import { BuilderComponent } from '@builder.io/react';
import { useRouter } from 'next/router';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';

import { trackBuilderTest } from '~/builder/utils/builderTesting';
import { isBuilderPreview } from '~/builder/utils/isBuilderPreview';
import { useBuilderContent } from '~/builder/utils/useBuilderContent';
import useBuilderJson from '~/builder/utils/useBuilderJson';
import useIsServerSafeMobile from '~/shared/utils/useIsServerSafeMobile';
import useSecondarySite, {
  getSecondarySiteBuilderModelQuery,
} from '~/shared/utils/useSecondarySite';

import FallbackHeader from './components/FallbackHeader';
import HeaderBanner from './components/HeaderBanner';

export default function BuilderHeaderRenderer({ showPostRegTimer }) {
  const { query } = useRouter();
  const isMobile = useIsServerSafeMobile();
  const secondarySite = useSecondarySite();

  const headerSectionModelProperties = {
    modelName: 'header',
    query: {
      data: {
        variant: isMobile ? 'mobile' : 'desktop',
        secondarySite: getSecondarySiteBuilderModelQuery(secondarySite),
      },
    },
    builderQueries: {
      isPreview: isBuilderPreview({ query }),
    },
  };

  const dispatch = useDispatch();
  const { builderJson, isLoading } = useBuilderJson(
    headerSectionModelProperties
  );
  const { content, locale } = useBuilderContent(builderJson);

  if (isLoading || !builderJson) {
    return <FallbackHeader />;
  }

  return (
    <>
      <BuilderComponent
        model="header"
        content={content}
        locale={locale}
        contentLoaded={(data, content) => {
          trackBuilderTest(dispatch, content);
        }}
      />
      <HeaderBanner showPostRegTimer={showPostRegTimer} />
    </>
  );
}

BuilderHeaderRenderer.propTypes = {
  showPostRegTimer: PropTypes.bool,
};
