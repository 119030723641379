import { useMemo } from 'react';

import { useRouter } from 'next/router';

import comparePathToUrl from './comparePathToUrl';

export default function usePathHierarchy(url) {
  // using asPath because builder pages show route as their builder page map
  // and thus is useless for this path hierarchy.  The asPath property does
  // include possible query params and thus the need for stripping
  const { asPath } = useRouter();

  return useMemo(() => {
    const querylessPath = asPath?.split('?') ? asPath?.split('?')[0] : null;

    return comparePathToUrl(querylessPath, url);
  }, [asPath, url]);
}
