import React, { useRef } from 'react';

import PropTypes from 'prop-types';
import { animated, useTransition } from 'react-spring';
import styled from 'styled-components';

import {
  desktop,
  mobile,
  useCountdown,
} from '~/techstyle-shared/react-components';
import { defineMessages, useIntl } from '~/techstyle-shared/react-intl';

import usePostRegCountdownEndTime from '../../utils/usePostRegCountdownEndTime';
import VisuallyHidden from '../VisuallyHidden';

const AriaHiddenWrapper = styled.div`
  ${mobile`
    order: 2;
    margin-top: ${({ theme }) => theme.sizes.xs}px;
    `}

  ${({ theme }) => theme.tabsNode.bannerAriaHiddenWrapper};
`;

// styled(Text)this causes a problem. resolves to 'p' but has children
const StyledText = styled.div` 
  display: flex;
  flex-direction: row nowrap;
  align-items: center;
  justify-content: center;
  align-self: center;
  padding-left: ${({ theme: { sizes } }) => sizes.sm}px;
  padding-bottom: ${({ theme: { sizes } }) => sizes.xxxs}px;
  gap: 0.5ch;
  margin-right: ${({ theme }) => theme.sizes.sm}px};
  ${desktop`
    padding-bottom: 0;
  `}

  ${({ theme }) => theme.tabsNode.bannerCopy};
  line-height: 25px;
`;

const CountdownMessage = styled.span`
  flex: 0 0 auto;
  ${({ theme: { countdownStyles } }) => countdownStyles.postreg.text};
`;

const DigitsWrapper = styled.div`
  flex: 1 1 100%;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  align-self: flex-start;
  letter-spacing: 0.02em;
  overflow: hidden;

  ${({ theme }) => theme.tabsNode.countdownDigits};
  ${({ theme: { countdownStyles } }) => countdownStyles.postreg.text};
`;

const TimeWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(2, auto);
  grid-template-rows: auto;
  position: relative;
  overflow: visible;
  width: auto;
  // Setting min-width to 2.1ch so Countdown isn't constantly
  // readjusting width (e.g. when "1" --> "0")
  min-width: 2.1ch;
`;

const DigitWrapper = animated(styled.div`
  grid-area: ${({ $index }) => `1 / ${$index + 1} / 1 / ${$index + 1}`};
  width: auto;
  text-align: ${({ $index }) => ($index === 1 ? 'left' : 'right')};
`);

const Digit = animated(styled.div`
  transform: translate3d(0, 100%, 0);
`);

const DigitSeparator = styled.div`
  float: left;
`;

const PostregWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  ${({ theme }) => theme.tabsNode.countdownWrapper};
`;

const messages = defineMessages({
  timerCopy: {
    id: 'post_reg.timer_copy',
    defaultMessage: 'Offer Expires in',
  },
  otherPagesCopy: {
    id: 'post_reg.timer_copy_other_pages',
    defaultMessage: 'Your 2 for $29 Bottoms offer expires in',
  },
});

export default function PostregCountdown({
  children = null,
  onPostRegPage = false,
  timerText = null,
  ...rest
}) {
  const { formatMessage } = useIntl();
  const { countDownEndTime } = usePostRegCountdownEndTime();
  const { isTimerActive, minutes, padNumber, seconds } =
    useCountdown(countDownEndTime);

  const countdownTypographyVariant = 'postRegTabCountDownTimer';

  // Show the text entered from builder if it exists, otherwise use existing messages content
  let countDownCopy;
  if (timerText) {
    // If we have a timer text coming in, it will already be localized and we do not need to formatMessage
    countDownCopy = timerText;
  } else {
    countDownCopy = onPostRegPage
      ? messages.timerCopy
      : messages.otherPagesCopy;
  }

  // ARIA role=timer value for screen readers
  const countdownString = useRef(
    formatMessage(
      {
        id: 'post_reg.timer_string',
        defaultMessage: '{minutes} minutes and {seconds} seconds',
      },
      {
        minutes,
        seconds,
      }
    )
  );
  const countdownMinutes = padNumber(minutes);
  const countdownSeconds = padNumber(seconds);

  const transitionProps = (key) => ({
    from: {
      opacity: 0,
      transform: `translate3d(0, 100%, 0)`,
    },
    enter: {
      opacity: 1,
      transform: `translate3d(0, 0, 0)`,
      reset: true,
    },
    leave: {
      transform: `translate3d(0, -125%, 0)`,
      reset: true,
      config: {
        tension: 700,
        friction: 40,
      },
    },
    config: {
      tension: 600,
      friction: 40,
    },
    onRest: () => {},
    keys: (digit, index) => `${key}${index}_${digit}`,
  });

  const minuteTransitions = useTransition(
    [...countdownMinutes],
    transitionProps('m')
  );
  const secondTransitions = useTransition(
    [...countdownSeconds],
    transitionProps('s')
  );

  function getIndex(key) {
    // Presumes key supplied to transitionProps() is one character long.
    return +key.charAt(1);
  }

  if (isTimerActive) {
    return (
      <PostregWrapper data-builder-component-type="postreg_countdown">
        <VisuallyHidden role="status">
          {timerText ? countDownCopy : formatMessage(countDownCopy)}{' '}
          {countdownString.current}
        </VisuallyHidden>
        <AriaHiddenWrapper aria-hidden>
          <StyledText variant={countdownTypographyVariant} {...rest}>
            <CountdownMessage>
              {timerText ? countDownCopy : formatMessage(countDownCopy)}
            </CountdownMessage>
            <DigitsWrapper>
              <TimeWrapper>
                {minuteTransitions((style, digit, context, index) => (
                  <DigitWrapper
                    $index={getIndex(context.key)}
                    style={{ opacity: style.opacity }}
                  >
                    <Digit style={{ transform: style.transform }}>
                      {digit}
                    </Digit>
                  </DigitWrapper>
                ))}
              </TimeWrapper>
              <DigitSeparator>:</DigitSeparator>
              <TimeWrapper>
                {secondTransitions((style, digit, context, index) => (
                  <DigitWrapper
                    $index={getIndex(context.key)}
                    style={{ opacity: style.opacity }}
                  >
                    <Digit style={{ transform: style.transform }}>
                      {digit}
                    </Digit>
                  </DigitWrapper>
                ))}
              </TimeWrapper>
            </DigitsWrapper>
          </StyledText>
        </AriaHiddenWrapper>
      </PostregWrapper>
    );
  }

  return children;
}

PostregCountdown.propTypes = {
  children: PropTypes.node,
  onPostRegPage: PropTypes.bool,
  timerText: PropTypes.string,
};
