import routeMappings from './routeMappings';

const addSlash = (path) => path.replace(/^\/?/, '/');

// TODO: figure out way to localize the parameterized URLs
export default function getLocalizedPath({ path, tld }) {
  if (!path) {
    return path;
  }

  if (!path.startsWith('http')) {
    path = addSlash(path);
  }
  const mapping = routeMappings.find(
    (mapping) => addSlash(mapping.page) === path
  );
  if (!mapping || !mapping.intl || !mapping.intl[tld]) {
    return path;
  }
  return mapping.intl[tld];
}
