export const WEBCHAT_OPEN_COUNTDOWN = 2000;

export const WEBCHAT_COOKIE_EXPIRATION = 180 * 60 * 1000; // 3 hours

export const CUSTOMER_DETAILS_MAP = {
  'webchat/open': 'cognigy_webchat_open',
  'webchat/close': 'cognigy_webchat_close',
};

export const SEGMENT_EVENTS_MAP = {
  'webchat/incoming-message': 'Cognigy Webchat Incoming',
  'webchat/outgoing-message': 'Cognigy Webchat Outgoing',
};

export const COGNIGY_COOKIE_NAME = 'FL_GENERAL_COGNIGY_WIDGET_ALWAYS_SHOW';

export const DISABLE_WIDGET_TOGGLE = {
  widgetSettings: { disableToggleButton: true },
};

export const ENABLE_WIDGET_TOGGLE = {
  widgetSettings: { disableToggleButton: false },
};
