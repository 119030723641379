import React from 'react';

import PropTypes from 'prop-types';

import { Link as BaseLink } from '~/techstyle-shared/next-routes';

import Text from '../Text';

function Link({
  as = 'a',
  href,
  passHref,
  children,
  prefetch,
  route,
  to,
  asPath,
  className,
  variant,
  ...rest
}) {
  return (
    <BaseLink
      href={href}
      asPath={asPath}
      passHref={passHref}
      prefetch={prefetch}
      route={route}
      to={to}
    >
      <Text as={as} className={className} variant={variant} {...rest}>
        {children}
      </Text>
    </BaseLink>
  );
}

Link.propTypes = {
  as: PropTypes.elementType,
  asPath: PropTypes.string,
  children: PropTypes.node,
  className: PropTypes.string,
  href: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  passHref: PropTypes.bool,
  prefetch: PropTypes.bool,
  route: PropTypes.string,
  to: PropTypes.string,
  variant: PropTypes.string,
};

export default Link;
